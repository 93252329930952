@import '@scss/_module';

.date_table_holder {
  padding-top: rem(4);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: rem(8);
  overflow: hidden;

  tr:first-child {
    th, td {
      border-top: 0;
    }
  }
}

.date_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  // margin-bottom: 16px;
  font-weight: 500;
  font-size: rem(12);
  line-height: 1;

  th {
    // height: 2rem;
    color: $codgray;
    width: 100%;
    position: relative;
    font-weight: inherit;
    padding: rem(8) 5px rem(12);
    text-align: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    
    border: 1px solid transparent;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    &:not(:empty) {
      cursor: pointer;
    }

    &:first-child {
      width: 13rem;
      padding-left: rem(10);
      padding-right: rem(10);
    }

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      right: -1px;
      height: 100%;
      border-radius: rem(8) rem(8) 0 0;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: $geyser;
      background: $white;
      opacity: 0;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15), inset 0 -3px 0 0 $blue;
      transition: opacity .25s linear;
    }

    // &::before,
    // &::after {
    //   content: ' ';
    //   bottom: -1px;
    //   position: absolute;
    //   top: -1px;
    //   width: 15px;
    //   z-index: 10;
    //   transition: opacity $anim-func-base $animspeed-base;
    //   opacity: 0;
    // }

    // &::before {
    //   box-shadow: inset -15px 0 10px -20px #000;
    //   left: -15px;
    // }

    // &::after {
    //   box-shadow: inset 15px 0 10px -20px #000;
    //   right: -15px;
    // }

    &.past {
      color: rgba(0, 0, 0, 0.3);
      background: rgba(#000, 0.04);

      &::before,
      &::after {
        display: none;
      }
    }

    &.selected {
      z-index: 20;
      width: 11rem;
      // border-color: $border-color;

      &::before,
      &::after {
        opacity: 1;
      }
    }

    &.borderless {
      border-left: transparent;
      border-right: transparent;
    }
  }

  &:first-child .header th,
  &:first-child .header td {
    // box-shadow: inset 0 2px 0 0 rgba(126, 126, 126, 0.15);

    &.extended {
      box-shadow: none;
    }
  }

  td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  tbody {
    tr {
      &:hover td {
        background-color: rgba(43, 159, 217, 0.05);
      }

      &:last-child {
        th, td {
          border-bottom: 0;
        }
      }
    }

    td {
      position: relative;
      z-index: 1;
    }
  }

  .empty {
    th,
    td {
      background: #fff !important;
    }
  }

  tr:first-child {
    th,
    td {
      border-top: 0 !important;
    }
  }

  thead:last-child tr:last-child {
    th,
    td {
      border-bottom: 0;
    }
  }
}

.header_top {
  th {
    &::before,
    &::after {
      display: none;
    }
  }

  .item_title {
    border-right: transparent;
  }
}

.link_edit {
  @include link;

  i {
    width: rem(15);
    height: rem(19);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.537 3.288L10.171.943a.742.742 0 00-1.069 0l-7.1 7.036c-.076.075-.076.151-.153.227v.075L.63 11.837c-.077.303 0 .605.152.757a.696.696 0 00.534.226c.076 0 .153 0 .229-.075l3.588-1.21h.076c.076 0 .153-.075.229-.151l7.1-7.036a.728.728 0 000-1.06z' fill='%237F8B96'/%3E%3Cpath d='M10.934 3.817l-.381.378L9.255 2.91l.381-.378 1.298 1.286zM4.902 9.795L3.605 8.509l4.581-4.54 1.298 1.287-4.582 4.539zM2.536 10.852l.305-.907.611.605-.916.302z' fill='%23fff'/%3E%3C/svg%3E");
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.537 3.288L10.171.943a.742.742 0 00-1.069 0l-7.1 7.036c-.076.075-.076.151-.153.227v.075L.63 11.837c-.077.303 0 .605.152.757a.696.696 0 00.534.226c.076 0 .153 0 .229-.075l3.588-1.21h.076c.076 0 .153-.075.229-.151l7.1-7.036a.728.728 0 000-1.06z' fill='%23FFFFFF'/%3E%3Cpath d='M10.934 3.817l-.381.378L9.255 2.91l.381-.378 1.298 1.286zM4.902 9.795L3.605 8.509l4.581-4.54 1.298 1.287-4.582 4.539zM2.536 10.852l.305-.907.611.605-.916.302z' fill='%23009bff'/%3E%3C/svg%3E");
      opacity: 0;
    }
  }
}
