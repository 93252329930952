.icon-add {
  $size: 19px;
  $icon-size: 9px;

  position: relative;
  width: $size;
  height: $size;
  background-color: $lightblue;
  border-radius: 50%;
  margin-right: rem(6);

  &::before,
  &::after {
    content: "";
    position: absolute;
    overflow: hidden;
    background: $blue;
  }

  &::before {
    top: calc((#{$size} - 1px) / 2);
    left: calc((#{$size} - #{$icon-size}) / 2);
    width: $icon-size;
    height: 1px;
  }

  &::after {
    top: calc((#{$size} - #{$icon-size}) / 2);
    left: calc((#{$size} - 1px) / 2);
    height: $icon-size;
    width: 1px;
  }
}