
.timezone-select {

  .timezone__control {
    border: 0 !important;
    box-shadow: none !important;
  }

  .timezone__single-value,
  .timezone__option {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #566068;
    
  }

  .timezone__group-heading {
    color: #151515;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
  }

  .timezone__indicator-separator {
    display: none;
  }

  .timezone__indicator {
    color: #7F8B96;
    padding: 0;
    > svg {
      display: none;
    }
    &:after {
      content: '';
      display: block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #7F8B96;
    }
  }

}