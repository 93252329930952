.date_picker_wrapper {
  border: 1px solid #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  
  .DateInput {
    display: none;
  }

  .SingleDatePicker_picker {
    position: static;
  }

  .DayPicker {
    box-shadow: none;
  }

}