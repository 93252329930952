.wrapper {
  display: flex;
  flex-direction: column;
}

.selected {
  margin-bottom: 1rem;
}

.selected_items {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.list_wrapper {
  flex: 1 0 auto;
  height: 10rem;
  margin-top: 1rem;
}

.list {
  padding-left: 0.5rem;
  height: 100%;
  overflow-y: auto;
}

.label {
  margin-bottom: 0.5rem;
}