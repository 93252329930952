@import '@scss/_module';

.wrapper {
  display: flex;
  background-color: #E2F3FF;
  border-radius: 0.25rem;
}

.text {
  padding: 4px 4px 6px 8px;
  color: #009BFF;
    font-size: 0.75rem;
}

.remove {
  position: relative;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: rem(5);

  &::before {
    content: "";
    display: block;
    width: rem(16);
    height: rem(16);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23fff'/%3E%3Ccircle cx='8' cy='8' r='7.5' stroke='%23009BFF' stroke-opacity='.25'/%3E%3Cpath d='M5 5l6 6M11 5l-6 6' stroke='%23009BFF' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  // &:hover {
  //   // background: $blue;
  // }
}

.css-6q0nyr-Svg {

}