@import '@scss/_module';

.promo {
  color: $regentgray;
  height: 100%;
  background: url('../../images/bg-promo.jpg') no-repeat 50% 0;
  background-size: cover;
  font-weight: 500;
  font-size: rem(18);
  line-height: rem(26);
  padding: rem(80) rem(20);
}

.promo_headline {
  color: $geyser;
  position: relative;
  max-width: rem(380);
  padding-bottom: rem(52);
  margin: 0 auto rem(46);
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: rem(36);
    height: 2px;
    margin-left: rem(-18);
    background-color: $shuttlegray;
  }

  h2 {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.rating_holder {
  width: 150px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: rem(7);
}

.rating_stars {
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0l3.4 7.32 8.012.972-5.912 5.495 1.553 7.92L15 17.784l-7.053 3.924 1.552-7.92-5.912-5.495L11.6 7.32 15 0z' fill='%23FCD940'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-position: 0 50%;
}

.promo_body {
  max-width: rem(430);
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    margin-bottom: rem(10);
  }
}

.promo_blockquote {
  color: $white;
  font-weight: 600;
  font-size: rem(22);
  line-height: rem(36);

  q {
    margin: 0;
  }
}

.promo_cite {
  display: block;
  font-style: inherit;
  font-size: rem(14);
  line-height: rem(16);
  margin-top: rem(36);

  img {
    display: block;
    width: rem(50);
    height: auto;
    border-radius: 50%;
    margin: 0 auto rem(14);
  }

  strong {
    display: block;
    font-weight: inherit;
    font-size: rem(16);
    margin-bottom: rem(5);
  }

  em {
    color: $regentgray;
    font-style: inherit;
    display: block;
  }
}
