body,
.non-auth-container {
  min-width: $min-width-base;
  font-family: $font-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  color: $codgray;
  line-height: 1;

  .card & {
    margin-bottom: rem(20);
  }
}

h3 {
  font-weight: 600;
  line-height: 1.1111;
}

h4 {
  font-weight: 600;
  line-height: 1.42857;
}

h5 {
  font-weight: 600;
  line-height: 1.53846;
}

h4 {
  font-weight: 600;
  line-height: 1.53846;
}

p {
  font-family: $font-alt;
}