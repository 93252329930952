@import '@scss/_module';

.group_table_holder {
  border: 1px solid $geyser;
  margin-bottom: rem(16);
  border-radius: rem(8);
  background-color: $white;

  & + & thead th {
    &::before,
    &::after {
      top: -17px;
    }
  }
}

.group_table_holder,
.group_table_inner {
  position: relative;

  &::before,
  &::after {
    z-index: 10;
    content: "";
    position: absolute;
    top: -2px;
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
  }
  
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M9 0H0v9h2a7 7 0 017-7V0z' fill='%23F7FAFC'/%3E%3Crect x='1.5' y='1.5' width='15' height='15' rx='7.5' stroke='%23D5DFE6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h9v9H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    left: -2px;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M0 0h9v9H7a7 7 0 00-7-7V0z' fill='%23F7FAFC'/%3E%3Crect x='-.5' y='.5' width='15' height='15' rx='7.5' transform='matrix(-1 0 0 1 7 1)' stroke='%23D5DFE6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h9v9H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    right: -2px;
  }
}

.group_table_inner {
  &::before,
  &::after {
    top: auto;
    bottom: -2px;
  }

  &::before {
    transform: rotate(-90deg);
  }

  &::after {
    transform: rotate(90deg);
  }
}

.group_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  font-weight: 500;
  font-size: rem(12);
  line-height: 1;
  margin-bottom: 0;

  th,
  td {
    padding: rem(7) rem(10) rem(9);
    width: 100%;
    height: rem(41);
    border: 1px solid $geyser;
    text-align: right;

    &:first-child {
      text-align: left;
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    &:not(:empty) {
      cursor: pointer;
    }

    &:first-child {
      width: 13rem;
    }

    &::before,
    &::after {
      content: ' ';
      bottom: -1px;
      position: absolute;
      top: -1px;
      width: 15px;
      z-index: 10;
      transition: opacity 0.25s linear;
      opacity: 0;
    }

    &::before {
      box-shadow: inset -14px 0px 14px -14px rgba(0, 0, 0, 0.15);
      // box-shadow: inset -15px 0 10px -20px #000;
      left: -15px;
      border-right: 1px solid $geyser;
    }

    &::after {
      box-shadow: inset 14px 0px 14px -14px rgba(0, 0, 0, 0.15);
      // box-shadow: inset 15px 0 10px -20px #000;
      right: -15px;
      border-left: 1px solid $geyser;
    }
  }

  .cell_progress {
    display: none;
  }

  td,
  th {
    position: relative;

    &.selected {
      z-index: 20;
      width: 11rem;

      &::before,
      &::after {
        opacity: 1;
      }

      .cell_data {
        display: none;
      }

      .cell_progress {
        display: block;
      }
    }
  }

  th {
    position: relative;
    white-space: nowrap;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      text-align: left;
    }
  }

  &:first-child thead {
    th,
    td {
      // box-shadow: inset 0 2px 0 0 rgba(126, 126, 126, 0.15);

      &.extended {
        box-shadow: none;
      }
    }
  }

  tbody {
    tr {
      &:hover td {
        background-color: $aliceblue;
      }
    }

    td {
      position: relative;
      z-index: 1;
    }
  }

  tr {
    &:first-child {
      th,
      td {
        border-top: 0;
      }
    }
  }

  tbody,
  thead:last-child {
    tr:last-child {
      th,
      td {
        border-bottom: 0 !important;
      }
    }
  }

  & + .group_table thead {
    th,
    td {
      &::before,
      &::after {
        top: -16px;
      }
    }
  }
}

.icon_open {
  position: relative;
  display: inline-block;
  vertical-align: 0;
  margin-right: 3px;

  &::before,
  &::after {
    content: '';
    width: rem(7);
    height: rem(10);
    overflow: hidden;
    transition: opacity 0.25s linear;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.398 9.096L.752 8.44l1.699-1.724 1.698-1.724L2.46 3.28C1.53 2.337.77 1.56.77 1.553.77 1.536 2.04.248 2.056.248c.007 0 1.066 1.07 2.354 2.376L6.752 5 4.41 7.375a316.625 316.625 0 01-2.354 2.376c-.007 0-.303-.294-.658-.655z' fill='%232E343A'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  &::before {
    display: inline-block;
    vertical-align: 1px;
    width: rem(10);
    height: rem(6);
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.656.646L1.311 0l1.724 1.699 1.724 1.698 1.713-1.689C7.415.778 8.192.018 8.2.018c.017 0 1.305 1.27 1.305 1.286 0 .007-1.07 1.066-2.376 2.354L4.752 6 2.377 3.658A316.843 316.843 0 010 1.304c0-.007.294-.303.655-.658z' fill='%232E343A'/%3E%3C/svg%3E");
    opacity: 0;

    .show & {
      opacity: 1;
    }
  }

  &::after {
    position: absolute;
    top: rem(2);
    left: 0;

    .show & {
      opacity: 0;
    }
  }
}

.icon_add {
  color: #fff;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // width: calc(100% - 3px);
  // height: calc(100% - 2px);
  border: 2px solid $blue;
  transition: opacity 0.25s linear;
  opacity: 0;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: rem(14);
    height: rem(14);
    background-color: $blue;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-linecap='round' d='M4.266.5v7M.5 3.735h7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .past & {
    display: none;
  }

  td:not(.past):hover & {
    opacity: 1;
  }
}

.group_table {
  thead {
    th {
      transition: color 0.25s linear;
      background: $catskillwhite !important;
    }

    tr {
      &:hover {
        // color: $primary;

        .icon_open::before {
          border-left-color: $primary;
        }
      }

      &.show:hover {
        .icon_open::after {
          border-top-color: $primary;
        }
      }
    }
  }
}

.title {
  color: $regentgray;
  display: block;
  font-size: rem(10);
  line-height: 1.2;
}
