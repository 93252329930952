.wrapper {
  padding-bottom: 5px;

  &.with_border {
    border-bottom: 1px solid #aaa;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  > div {
    width: 50%;

    &:last-child {
      width: 40%;
    }
  }
}

.time_slot {
  border: 1px solid #aaa;
  padding: 5px 8px;
  color: #7f8b96;
  font-weight: 600;
  text-transform: lowercase;
  border-radius: 4px;
  margin: 3px;
  cursor: default;

  &.selected {
    color: white;
    background:#009BFF;
    border: 1px solid #009BFF;
  }
}
