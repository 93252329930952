@import '@scss/_module';

// .project_menu {
//   > * {
//     margin-left: rem(8);
//     vertical-align: top;
//   }
// }

.project_updated {
  color: $silversand;
  font-size: rem(13);
  line-height: rem(35);
  text-transform: uppercase;
  padding-top: rem(2);

  strong {
    color: $regentgray;
    font-weight: inherit;
  }

  @media screen and (max-width: 570px) {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.link_update {
  @include link;
  opacity: 1;
}

.icon_update {
  width: rem(14);
  height: rem(15);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.385 0c-.298 0-.539.256-.539.571V2.66C9.074-.19 4.651-.113 1.967 2.829.702 4.215-.003 6.07 0 7.999c0 .317.241.572.538.572.298 0 .539-.255.539-.571C1.077 4.528 3.73 1.715 7 1.716c1.662 0 3.246.74 4.368 2.041l-2.385.844c-.283.1-.436.424-.342.723.094.3.4.463.682.363l3.23-1.143a.57.57 0 00.37-.544V.57c0-.315-.242-.571-.54-.571zM13.463 6.429c-.298 0-.539.256-.539.571 0 3.472-2.653 6.285-5.924 6.285-1.661 0-3.246-.742-4.368-2.042l2.386-.844c.282-.1.435-.424.34-.723a.533.533 0 00-.68-.363l-3.232 1.143a.57.57 0 00-.368.544v3.429c0 .315.241.571.539.571.297 0 .538-.256.538-.571V12.34c2.772 2.848 7.195 2.773 9.88-.17 1.264-1.386 1.97-3.241 1.966-5.17 0-.316-.24-.572-.538-.572z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.385 0c-.298 0-.539.256-.539.571V2.66C9.074-.19 4.651-.113 1.967 2.829.702 4.215-.003 6.07 0 7.999c0 .317.241.572.538.572.298 0 .539-.255.539-.571C1.077 4.528 3.73 1.715 7 1.716c1.662 0 3.246.74 4.368 2.041l-2.385.844c-.283.1-.436.424-.342.723.094.3.4.463.682.363l3.23-1.143a.57.57 0 00.37-.544V.57c0-.315-.242-.571-.54-.571zM13.463 6.429c-.298 0-.539.256-.539.571 0 3.472-2.653 6.285-5.924 6.285-1.661 0-3.246-.742-4.368-2.042l2.386-.844c.282-.1.435-.424.34-.723a.533.533 0 00-.68-.363l-3.232 1.143a.57.57 0 00-.368.544v3.429c0 .315.241.571.539.571.297 0 .538-.256.538-.571V12.34c2.772 2.848 7.195 2.773 9.88-.17 1.264-1.386 1.97-3.241 1.966-5.17 0-.316-.24-.572-.538-.572z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}

.link_edit {
  @include link;
  opacity: 1;
}

.icon_edit {
  width: rem(13);
  height: rem(13);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}
