.main {
  .app-footer {
    height: 50px;
    flex-basis: auto;
    // padding-top: rem(16);
    // padding-bottom: rem(15);
    margin-left: 0 !important;
  }
}

.main-container {
  min-height: calc(100vh - 50px);

  > .container-fluid {
    padding-left: #{$container-gap};
    padding-right: #{$container-gap};
  
    // @media screen and (max-width: 580px) {
    //   padding-left: rem(15);
    //   padding-right: rem(15);
    // }
  }
}
