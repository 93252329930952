.card-header {
  color: $shuttlegray;
  border-bottom: 1px solid $geyser;
  padding-top: rem(25);
  padding-bottom: rem(21);

  h3 {
    color: $codgray;
  }

  p {
    margin-bottom: 0;
  }
}

.card-body {
  padding-top: rem(23);
  padding-bottom: rem(23);
  // padding-left: $card-spacer-y;
  // padding-right: $card-spacer-y;
}

.card-footer,
.form-footer {
  padding-top: rem(23);
  padding-bottom: rem(23);
  border-top: 1px solid $geyser;
}