// fix
.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.625rem;
}

// default
.btn-default {
  color: $codgray;
  border: 1px solid $geyser;
  background: $white;

  &:hover,
  &:focus {
    background: $blacksqueeze;
    // color: $white;
    // background: $blue;
    // border-color: $blue;
  }

  @media screen and (max-width: 374px) {
    padding-left: rem(18);
    padding-right: rem(18);

    i {
      display: none;
    }
  }
}

.btn-tertiary {
  color: $blue;
  background: $lightblue;

  &:hover,
  &:focus {
    color: $lightblue;
    background: $blue;
  }
}

.btn-outline-secondary {
  &,
  &:hover,
  &:focus {
    color: $heavymetal;
  }

  &:hover,
  &:focus {
    background-color: $blacksqueeze;
  }
}

.btn-reset {
  color: $regentgray;
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  white-space: nowrap;
  border: 0;
  padding: 0;

  &:hover,
  &:focus {
    color: $body-color;
  }
}

// .icon-arrow-right {
//   display: inline-block;
//   vertical-align: middle;
//   width: rem(7);
//   height: rem(9);
//   background-image: url("data:image/svg+xml,%3Csvg width='7' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.089 7.83l4-3.502-4-3.503' stroke='%23fff' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
//   background-position: 50% 50%;
//   background-size: contain;

//   &.c-red {
//     background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.089 8.415l4-3.503-4-3.503' stroke='%23DF425A' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
//   }
// }

.simple-link {
  font-weight: 600;
  transition: color $anim-func-base $animspeed-base;
  white-space: nowrap;
  // font-size: rem(13);
  // line-height: rem(16);

  &:hover,
  &:focus {
    color: $body-color;
    text-decoration: none;
  }
}

.btn-inline {
  &:hover,
  &:focus {
    color: $blue;
  }
}
